import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Constantes from '../Constantes';
import { UserContext } from './UserContext';
import { bank_account } from '../ConsultasAPI/auth';
import { Diverror, Loader, Reg, onChange } from '../ConsultasAPI/login';
import ScriptTag from 'react-script-tag';

const Card = (props) => {
/*
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://js.stripe.com/v3/";
  
    document.head.appendChild(script);
  
    return () => {
      document.head.removeChild(script);
    }
  }, []);
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://hydrogen.wakeapp.org/pagos/payment/script.js";
    script.defer = true;
  
    document.head.appendChild(script);
  
    return () => {
      document.head.removeChild(script);
    }
  }, []);
  */

  //Variable error, vista y cargador
  const [error ,setError ] = useState("");
  const [loader ,setLoader ] = useState(false);
  const { user1, setUser1 } = useContext(UserContext);

  return (
    
    <div className='popUp'>
      <ScriptTag type="text/javascript" src="https://js.stripe.com/v3/" />
      <ScriptTag type="text/javascript" defer src="https://hydrogen.wakeapp.org/pagos/payment/script.js" />
      <button className="closeForm" onClick={() => {props.setView(false);setError('')}}>X</button>
      <div className="sr-root">
      <div className="sr-main">
        <form id="payment-form" className="sr-payment-form">
          <div className="sr-combo-inputs-row">
            <div className="sr-input sr-card-element" id="card-element"></div>
          </div>
          <div className="sr-field-error" id="card-errors" role="alert"></div>
          <button id="submit">
            <div className="spinner hidden" id="spinner"></div>
            <span id="button-text">Pay</span><span id="order-amount"></span>
          </button>
        </form>
        <div className="sr-result hidden">
          <p>Payment completed<br /></p>
          <pre>
            <code></code>
          </pre>
        </div>
      </div>
    </div>


    
    </div>
    );
  
  
};


export default Card;
